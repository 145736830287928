/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

 // Temporary Data
import commonData from "../data/common-data.json"

// Components
import Header from "./Header"
import Footer from "./Footer"
import SEO from "./seo";



import "../scss/custom.scss"
 
 const Layout = (props) => {

   const data = useStaticQuery(graphql`
     query siteLayout {  
       glstrapi{
        siteConfig {
          Footer_Bottom_Links {
            all_menus {
              Label
              Primary_URL
              Secondary_URL
              id
            }
          }
          site_footer_logos {            
            display_logos_in_footer
            Footer_Logos {
                id
                Logo_Link
                Image {
                  url
                  alternativeText
                }
            }
          }
          site_social_icons {
            display_icons_in_footer
            icons_list {
              id
              Facebook_Link
              Insta_Link
              Twitter_Link
            }
          }
          Footer_Menu_Component {
            id
            Heading
            Heading_Link
            Menu_Module {
              Custom_Link
              Label
              all_menu {
                Label
                Primary_URL
                Secondary_URL
                Parent_Menu {
                  id
                  Primary_URL
                  Label
                }
                Sub_Menu {
                  Label
                  Primary_URL
                  id
                }
                Secondary_Sub_Menu{
                  Label
                  Primary_URL
                  id
                }
                
              }
            }
          }

        }


        allMenus(sort: "Sort_Order:ASC", publicationState: LIVE) {
          id
          Label
          Link_Type
          Sort_Order
          Primary_URL
          Secondary_URL
          Show_Header_Menu
          Show_Burger_Menu
          Show_Footer_Menu
          LoggedInMenu
          Parent_Menu {
            Label
            id
            Primary_URL
          }
          Sub_Menu {
            id
            Label
            Primary_URL
          }
          Secondary_Sub_Menu{
            Label
            Primary_URL
            id
          }
          article {
            id
            Pagename
            Alias
          }
        }
 
       }
     }
   `)

   var site_menu = data.glstrapi?.allMenus;
   var site_config = data.glstrapi?.siteConfig;

   return <>
    <Helmet>
      <script type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=fkxh58yexjdmxksf2ypawg" async="true"></script>
    </Helmet>
    {props.template !== "property_search" && 
      <SEO title={props?.Meta_Title} description={props?.Meta_Description} />
    }
     
     <div className={`page-wrapper ${props?.page_class} ${props?.layout}`}>
         <Header variant={props.variant ? props.variant : ""} headerData={ commonData } menu_Data={site_menu} />
         
         <main className="">
           {props.children}
         </main>

         <Footer
          footerData={ site_config }
          menu_slug={props.menu_slug}
          // footerData={ commonData.FooterMenu }
          // footerTermsMenu={ commonData.FooterTermsMenu }
          // footerSocialLinks={ commonData.FooterSocialLinks }
          // footerCompanies={ commonData.FooterCompanies }
        />
     </div>
    </>
 }
 
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default Layout 